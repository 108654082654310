import { ChangeEvent, FC, FormEvent, useEffect, useState } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { Box, Button, CircularProgress, TextField, Typography } from '@mui/material';
//components
import { Alert } from '../../../components/common/Alert';
import { AuthContainer, AuthLayoutContainer } from '../../../theme/styleComponents';
//other block
import { defenderReviewAPI } from '../../../utils';
import { LOGIN_ROUTE } from '../../../constants';

const DefenderReviewApi: FC = () => {
  const navigate = useNavigate();
  const [searchParam] = useSearchParams();
  const [text, setText] = useState<string>('');
  const [pastedText, setPastedText] = useState<string>('');
  const [ispastedText, setIsPastedText] = useState<Boolean>(false);
  const [inputStartTime, setInputStartTime] = useState(0);
  const [loading, setLoading] = useState<boolean>(false);
  const [loadTime, setLoadTime] = useState<number | null>(null);

  const panelistId = searchParam.get("panelistId") || "";
  const projectId = searchParam.get("projectId") || "";
  const mode = searchParam.get("mode") || "";
  const supplierQuotaGroupId = searchParam.get("supplierQuotaGroupId") || "";

  const handleSubmit = async (event: FormEvent<HTMLFormElement>) => {
    try {
      event?.preventDefault();
      if (text) {
        setLoading(true)
        const encodedText = ispastedText === true ? pastedText : "";
        const etime = inputStartTime + 'ms';
        const ptime = loadTime?.toFixed(2) + 'ms';
        const result = await defenderReviewAPI(text, panelistId, encodedText, etime, ptime);
        const { score: { composite_score = 0, } = {} } = result || {}
        navigate(`/survey/verify?projectId=${projectId}&supplierQuotaGroupId=${supplierQuotaGroupId}&panelistId=${panelistId}${mode ? "&mode=test" : ""}&dataQualityScore=${composite_score}&qualityCheckSampleText=${text}`)
        setLoading(false)
      } else {
        Alert.error('Please Type Something.')
      }
    } catch (error) {
      setLoading(false)
    }
  };

  const handleChange = (event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    const { target: { value = "" } } = event;
    // setIsPastedText(false);    
    setText(value);
    if (inputStartTime === null) { setInputStartTime(new Date().getTime()); }
  };

  const handlePastedChange = (event: any) => {
    setIsPastedText(true);
    const pastedText = event.clipboardData.getData('text');
    setPastedText(pastedText);
  };

  useEffect(() => {
    const startTime = performance.now();

    // Simulate some asynchronous operations (e.g., fetching data)
    // Replace this with your actual asynchronous operations

    // For demonstration purposes, let's use setTimeout to simulate an asynchronous operation
    const asyncOperation = () => {
      return new Promise((resolve) => {
        setTimeout(resolve, 2000); // Simulating a delay of 2000 milliseconds (2 seconds)
      });
    };

    // Perform asynchronous operations
    asyncOperation().then(() => {
      // Capture the end time when the asynchronous operations are complete
      const endTime = performance.now();

      // Calculate the time taken in milliseconds
      const timeTaken = endTime - startTime;

      // Update the state with the time taken
      setLoadTime(timeTaken);
    });
    const inputEndTime = new Date().getTime();
    const inputTimeTaken = inputEndTime - inputStartTime;
    setInputStartTime(inputTimeTaken);
    if (!projectId || !panelistId || !supplierQuotaGroupId) {
      navigate(LOGIN_ROUTE)
    }
  }, [inputStartTime, panelistId, projectId, supplierQuotaGroupId, navigate]);

  return (
    <AuthLayoutContainer>
      <Box maxWidth='543px' margin="auto" width='100%'>
        <AuthContainer>
          <Typography variant="h6" marginBottom='10px'>
            Why do you take surveys?
          </Typography>

          <form onSubmit={handleSubmit}>
            <TextField
              label=""
              value={text}
              onChange={handleChange}
              onPaste={handlePastedChange}
              fullWidth
              disabled={loading}
            />

            <Button
              type="submit"
              fullWidth
              variant="contained"
              sx={{
                mt: 3, mb: 2,
              }}
              disabled={loading}
              endIcon={
                loading && (
                  <CircularProgress size={20} color="inherit" />
                )
              }
            >
              Continue
            </Button>
          </form>
        </AuthContainer>
      </Box>
    </AuthLayoutContainer>
  )
}

export default DefenderReviewApi