// All States of America
export const USState = [
  { state: "New York", value: "newyork" },
  { state: "Alabama", value: "alabama" },
  { state: "Alaska", value: "alaska" },
  { state: "Arizona", value: "arizona" },
  { state: "Arkansas", value: "arkansas" },
  { state: "California", value: "california" },
  { state: "Colorado", value: "colorado" },
  { state: "Connecticut", value: "connecticut" },
  { state: "Delaware", value: "delaware" },
  { state: "Florida", value: "florida" },
  { state: "Georgia", value: "georgia" },
  { state: "Hawaii", value: "hawaii" },
  { state: "Idaho", value: "idaho" },
  { state: "Illinois", value: "illinois" },
  { state: "Indiana", value: "indiana" },
  { state: "Iowa", value: "iowa" },
  { state: "Kansas", value: "kansas" },
  { state: "Kentucky", value: "kentucky" },
  { state: "Louisiana", value: "louisiana" },
  { state: "Maine", value: "maine" },
  { state: "Maryland", value: "maryland" },
  { state: "Massachusetts", value: "massachusetts" },
  { state: "Michigan", value: "michigan" },
  { state: "Minnesota", value: "minnesota" },
  { state: "Mississippi", value: "mississippi" },
  { state: "Missouri", value: "missouri" },
  { state: "Montana", value: "montana" },
  { state: "Nebraska", value: "nebraska" },
  { state: "Nevada", value: "nevada" },
  { state: "New Hampshire", value: "newhampshire" },
  { state: "New Jersey", value: "newjersey" },
  { state: "New Mexico", value: "newmexico" },
  { state: "North Carolina", value: "northcarolina" },
  { state: "North Dakota", value: "northdakota" },
  { state: "Ohio", value: "ohio" },
  { state: "Oklahoma", value: "oklahoma" },
  { state: "Oregon", value: "oregon" },
  { state: "Pennsylvania", value: "pennsylvania" },
  { state: "Rhode Island", value: "rhodeisland" },
  { state: "South Carolina", value: "southcarolina" },
  { state: "South Dakota", value: "southdakota" },
  { state: "Tennessee", value: "tennessee" },
  { state: "Texas", value: "texas" },
  { state: "Utah", value: "utah" },
  { state: "Vermont", value: "vermont" },
  { state: "Virginia", value: "virginia" },
  { state: "Washington", value: "washington" },
  { state: "West Virginia", value: "westvirginia" },
  { state: "Wisconsin", value: "wisconsin" },
  { state: "Wyoming", value: "wyoming" },
];

// List of Austrailia's States
export const australiaState = [
  {
    state: "Australian Capital Territory",
    value: "australiancapitalterritory",
  },
  { state: "New South Wales", value: "newsouthwales" },
  { state: "Northern Territory", value: "northernterritory" },
  { state: "Queensland", value: "queensland" },
  { state: "South Australia", value: "southaustralia" },
  { state: "Tasmania", value: "tasmania" },
  { state: "Victoria", value: "victoria" },
  { state: "Western Australia", value: "westernaustralia" },
];

// List of Canada's States
export const canadaStates = [
  { state: "Alberta", value: "alberta" },
  { state: "British Columbia", value: "britishcolumbia" },
  { state: "Manitoba", value: "manitoba" },
  { state: "New Brunswick", value: "newbrunswick" },
  { state: "Newfoundland and Labrador", value: "newfoundlandandlabrador" },
  { state: "Northwest Territories", value: "northwestterritories" },
  { state: "Nova Scotia", value: "novascotia" },
  { state: "Nunavut", value: "nunavut" },
  { state: "Ontario", value: "ontario" },
  { state: "Prince Edward Island", value: "princeedwardisland" },
  { state: "Quebec", value: "quebec" },
  { state: "Saskatchewan", value: "saskatchewan" },
  { state: "Yukon", value: "yukon" },
];

// List of United Kingdom's States
export const UKStates = [
  { state: "England", value: "england" },
  { state: "Scotland", value: "scotland" },
  { state: "Wales", value: "wales" },
  { state: "Northern Ireland", value: "northernireland" },
];

// List of NewZealand States
export const NewZealandStates = [
  { state: "Auckland", value: "auckland" },
  { state: "Bay of Plenty", value: "bayofplenty" },
  { state: "Canterbury", value: "canterbury" },
  { state: "Chatham Islands Territory", value: "chathamislandsterritory" },
  { state: "Gisborne", value: "gisborne" },
  { state: "Hawke's Bay", value: "hawkesbay" },
  { state: "Manawatu-Wanganui", value: "manawatuwanganui" },
  { state: "Marlborough", value: "marlborough" },
  { state: "Nelson", value: "nelson" },
  { state: "Northland", value: "northland" },
  { state: "Otago", value: "otago" },
  { state: "Southland", value: "southland" },
  { state: "Taranaki", value: "taranaki" },
  { state: "Tasman", value: "tasman" },
  { state: "Waikato", value: "waikato" },
  { state: "Wellington", value: "wellington" },
  { state: "West Coast", value: "westcoast" },
];
