/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from "react";
import {
  Controller,
  FormProvider,
  SubmitHandler,
  useForm,
} from "react-hook-form";
import {
  Panelist,
  PanelistReviewStatus,
  UpdatePanelistInput,
  UserGender,
  useUpdatePanelistMutation,
} from "../../../generated";
import { Collapse, Grid, MenuItem, Select, Typography } from "@mui/material";
import CardComponent from "../../../components/common/CardComponent";
import {
  capitalizeFirstLetter,
  renderItem,
  mapEnums,
  formatDate,
} from "../../../utils";
import { GRAPHQL_QUERY_POLICY } from "../../../constants";
import { yupResolver } from "@hookform/resolvers/yup";
import { PanelistUpdateSchema } from "../../../validationSchema";
import { updatePanelistHandler } from "../../../utils/project";
import CommonController from "../../../components/common/CommonController";
import { Alert } from "../../../components/common/Alert";
export type IPanelistInfo = {
  panelist?: Panelist;
  loading: boolean;
  refreshData: Function;
};

const PanelistInfo = ({ panelist, refreshData }: IPanelistInfo) => {
  const [edit, setEdit] = useState<boolean>(false);

  const [updatePanelist] = useUpdatePanelistMutation({
    ...(GRAPHQL_QUERY_POLICY as any),
    onError() {
      return null;
    },

    onCompleted(data) {
      const {
        updatePanelist: { response },
      } = data;
      if (response) {
        const { status, message } = response;
        if (status && status === 200 && message) {
          Alert.toast(message);
          reset();
          refreshData();
          setEdit(false);
        }
      }
    },
  });

  useEffect(() => {
    if (panelist) {
      Object.keys(panelist).map((key) =>
        setValue(key as keyof Panelist, panelist[key as keyof Panelist])
      );
    }
  }, [panelist]);

  const methods = useForm({
    mode: "all",
    resolver: yupResolver(PanelistUpdateSchema),
    defaultValues: panelist,
  });

  const {
    handleSubmit,
    reset,
    formState: { isDirty },
    setValue,
  } = methods;

  const onSubmit: SubmitHandler<UpdatePanelistInput> = async (data) => {
    if (panelist && isDirty) {
      await updatePanelist({ variables: updatePanelistHandler(data) });
    }
  };

  useEffect(() => {
    if (!edit) {
      reset();
    }
  }, [edit, reset]);

  const handleActionEdit = () => {
    setEdit(!edit);
  };

  const {
    id,
    ipAddress,
    availablePoints,
    comment,
    pointsWithdrawn,
    loginCount,
    lastLogin,
  } = panelist || {};

  return (
    <FormProvider {...methods}>
      <form onSubmit={handleSubmit(onSubmit)}>
        <CardComponent
          cardTitle="Panelist Information"
          isEdit={edit}
          onEditClick={handleActionEdit}
          hasEdit
        >
          <Collapse in={edit} mountOnEnter unmountOnExit>
            <Grid container spacing={2}>
              <Grid item xs={12} md={6}>
                <Typography fontWeight={600}>Birth Date</Typography>
                <CommonController
                  fieldType="text"
                  controllerName="dob"
                  controllerLabel="Birth Date"
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <Typography fontWeight={600}>Phone No.</Typography>
                <CommonController
                  fieldType="text"
                  controllerName="phone"
                  controllerLabel="Phone No."
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <Typography fontWeight={600}>Gender</Typography>
                <Controller
                  name="gender"
                  render={({ field }) => (
                    <Select {...field} fullWidth sx={{ my: 1 }}>
                      {Object.values(UserGender)?.map((tc, index) => (
                        <MenuItem key={index} value={tc}>
                          {capitalizeFirstLetter(tc)}
                        </MenuItem>
                      ))}
                    </Select>
                  )}
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <Typography fontWeight={600}>Available Points</Typography>
                <CommonController
                  fieldType="text"
                  controllerName="availablePoints"
                  controllerLabel="Available Points"
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <Typography fontWeight={600}>Address</Typography>
                <CommonController
                  fieldType="text"
                  controllerName="address"
                  controllerLabel="Address"
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <Typography fontWeight={600}>City</Typography>
                <CommonController
                  fieldType="text"
                  controllerName="city"
                  controllerLabel="City"
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <Typography fontWeight={600}>State</Typography>
                <CommonController
                  fieldType="text"
                  controllerName="state"
                  controllerLabel="State"
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <Typography fontWeight={600}>Zip Code</Typography>
                <CommonController
                  fieldType="text"
                  controllerName="zipCode"
                  controllerLabel="Zip Code"
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <Typography fontWeight={600}>Panelist Review</Typography>
                <Controller
                  name="panelistReviewStatus"
                  render={({ field }) => (
                    <Select {...field} fullWidth sx={{ my: 1 }}>
                      {Object.values(PanelistReviewStatus).map(
                        (status, index) => (
                          <MenuItem key={index} value={status}>
                            {mapEnums(status)}
                          </MenuItem>
                        )
                      )}
                    </Select>
                  )}
                />
              </Grid>

              <Grid item xs={12} md={12}>
                <Typography fontWeight={600}>Note</Typography>
                <CommonController
                  fieldType="text"
                  controllerName="comment"
                  controllerLabel="Comment"
                  isMultiLine={true}
                />
              </Grid>
            </Grid>
          </Collapse>

          <Collapse in={!edit} mountOnEnter unmountOnExit>
            <Grid container spacing={3}>
              <Grid item xs={12} md={6}>
                {renderItem("Panel ID", `${id}`)}
              </Grid>

              <Grid item xs={12} md={6}>
                {renderItem("IP Address", `${ipAddress || "N/A"}`)}
              </Grid>

              <Grid item xs={12} md={6}>
                {renderItem("Available Points", `${availablePoints || "0"}`)}
              </Grid>
              <Grid item xs={12} md={6}>
                {renderItem(
                  "Life Time Points",
                  `${availablePoints || "0" + pointsWithdrawn || "0"}`
                )}
              </Grid>

              <Grid item xs={12} md={6}>
                {renderItem("Birth Date", `${panelist?.dob || "N/A"}`)}
              </Grid>
              <Grid item xs={12} md={6}>
                {renderItem("Phone No.", `${panelist?.phone || "N/A"}`)}
              </Grid>
              <Grid item xs={12} md={6}>
                {renderItem(
                  "Gender",
                  capitalizeFirstLetter(`${panelist?.gender || "N/A"}`)
                )}
              </Grid>
              <Grid item xs={12} md={6}>
                {renderItem("Address", `${panelist?.address || "N/A"}`)}
              </Grid>
              <Grid item xs={12} md={6}>
                {renderItem("City", `${panelist?.city || "N/A"}`)}
              </Grid>
              <Grid item xs={12} md={6}>
                {renderItem("State", `${panelist?.state || "N/A"}`)}
              </Grid>
              <Grid item xs={12} md={6}>
                {renderItem("Zip Code", `${panelist?.zipCode || "N/A"}`)}
              </Grid>
              <Grid item xs={12} md={6}>
                {renderItem(
                  "Panelist Review Status",
                  `${mapEnums(panelist?.panelistReviewStatus || undefined)}`
                )}
              </Grid>

              <Grid item xs={12} md={6}>
                {renderItem(
                  "Signup Source",
                  `${mapEnums(panelist?.signupSource || undefined)}`
                )}
              </Grid>
              <Grid item xs={6} md={6}>
                {renderItem("Login Count", loginCount || "N/A")}
              </Grid>

              <Grid item xs={6} md={6}>
                {renderItem(
                  "Last Login At",
                  lastLogin ? formatDate(+lastLogin) : "N/A"
                )}
              </Grid>

              <Grid item xs={6} md={6}>
                {renderItem("Note", comment || "N/A")}
              </Grid>
            </Grid>
          </Collapse>
        </CardComponent>
      </form>
    </FormProvider>
  );
};

export default PanelistInfo;
