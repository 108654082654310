import { FC } from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import { Box, CircularProgress } from "@mui/material";
import { FormProvider, SubmitHandler, useForm } from "react-hook-form";
import CommonController from "../../../components/common/CommonController";
import { yupResolver } from "@hookform/resolvers/yup";
import { resetPasswordValidationSchema } from "../../../validationSchema";
import { Alert } from "../../../components/common/Alert";
import { useUpdatePasswordByAdminMutation } from "../../../generated";
export const PanelistResetPassword: FC<any> = ({ open, setOpenUpdateModal, userId }): JSX.Element => {
    const methods = useForm({
        mode: "all",
        resolver: yupResolver(resetPasswordValidationSchema),
        defaultValues: {
            password: "",
            repeatPassword: ""
        }
    });

    const { handleSubmit, reset } = methods;
    const [updatePassword, { loading }] = useUpdatePasswordByAdminMutation({
        onCompleted: (data) => {
            if (data) {
                const { updatePasswordByAdmin: { response } } = data;
                if (response) {
                    const { status } = response;
                    if (status === 200) {
                        reset();
                        Alert.success(response.message as string);
                        handleClickClose()
                    }
                }
            }
        },
        onError: (error) => {
            Alert.error(error.message);
            handleClickClose()
        }
    })

    const handleClickClose = () => {
        setOpenUpdateModal(null);
        reset()
    };

    const onSubmit: SubmitHandler<any> = async (data: any) => {
        await updatePassword({
            variables: {
                updatePasswordByAdminInput: {
                    newPassword: data.password,
                    id: userId
                }
            },
        });
    }

    return (
        <>
            <Dialog open={open} onClose={handleClickClose} fullWidth>
                <DialogTitle>Reset user password</DialogTitle>
                <DialogContent>
                    <Box >
                        <FormProvider {...methods}>
                            <form onSubmit={handleSubmit(onSubmit)} autoComplete="off">
                                <CommonController
                                    controllerName="password"
                                    controllerLabel="New password"
                                    fieldType="password"
                                    isPassword={true}
                                />

                                <CommonController
                                    controllerName="repeatPassword"
                                    controllerLabel="Confirm password"
                                    fieldType="password"
                                    isPassword={true}
                                />

                                <Box marginTop='20px'>
                                    <Button
                                        variant="contained" fullWidth color="primary" type="submit"
                                        disabled={loading}
                                        endIcon={loading && <CircularProgress size={20} color="inherit" />}
                                    >
                                        Reset password
                                    </Button>
                                </Box>
                            </form>
                        </FormProvider>
                    </Box>

                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClickClose}>Cancel</Button>
                </DialogActions>
            </Dialog>
        </>
    );
};
