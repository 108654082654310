const palette = {
  primary: {
    main: "#771117"
  },
  secondary: {
    main: '#edf2ff',
  },
  warning: {
    main: '#FC5E5E',         // custom button color (red)
  },
  common: {
    mainLight: '#8d3d38',
    white: "#fff",
    black: "#000",
    steelFog: '#9CA3AF',
    carpetGray: '#4B5563',
    nightOwl: '#111827',
    zenWhite: '#E5E5E5',
    tornGray: '#E5E7EB',
    timberWolf: '#D1D5DB',
    vibrantBlack: '#3F3F3F',
    zipper: '#245D57',
    mussoliniWhite: "#ECF3F2",
    carnation: '#FC5E5E',
    honeyComb: '#f59e0b',
    richElectricBlue: '#1A8CDF',
    hanPurple: '#651ADF',
    brightGray: '#EBF3F1',
    sandGray: '#E2E8F0',
  }
}

export default palette;